export default {
  en: {
    translation: {
      addCustomLogo: 'Add custom logo',
      all: 'All',
      both: 'Both',
      cancel: 'Cancel',
      custom: 'Custom',
      customProperty: 'Custom property',
      default: 'Default',
      dragFile: 'Drag a file here',
      emailFrom: 'Email from',
      emailOrder: 'Email order',
      errSaveSettings: 'Error on saving settings',
      general: 'General',
      pnProp: 'Part number property',
      port: 'Port',
      propertyInseparableGroup: 'Property inseparable group',
      propertyRecommendedSpareParts: 'Property recommended spare parts',
      remove: 'Remove',
      reset: 'Reset',
      save: 'Save',
      sendOrder: 'Send order',
      settingsSaved: 'Settings saved',
      spareParts: 'Spare parts',
      user: 'User',
      viewer: 'Viewer',
    },
  },
  it: {
    translation: {
      addCustomLogo: 'Aggiungi logo personalizzato',
      all: 'Tutti',
      both: 'Entrambi',
      cancel: 'Annulla',
      custom: 'Personalizzato',
      customProperty: 'Proprietà personalizzata',
      default: 'Default',
      dragFile: 'Trascina un file qui',
      emailFrom: 'Email invio',
      emailOrder: 'Email ordine',
      errSaveSettings: 'Errore durante il salvataggio delle impostazioni',
      general: 'Generali',
      pnProp: 'Proprietà numero parte',
      port: 'Porta',
      propertyInseparableGroup: 'Proprietà gruppi inseparabili',
      propertyRecommendedSpareParts: 'Proprietà parti di ricambio consigliate',
      remove: 'Rimuovi',
      reset: 'Reset',
      save: 'Salva',
      sendOrder: 'Invia ordine',
      settingsSaved: 'Impostazioni salvate',
      spareParts: 'Parti di ricambio',
      user: 'Utente',
      viewer: 'Visualizzatore',
    },
  },
  es: {
    translation: {
      addCustomLogo: 'Añadir logotipo personalizado',
      all: 'Todas',
      cancel: 'Cancelar',
      customProperty: 'Propiedad personalizada',
      dragFile: 'Arrastra un archivo aquí',
      propertyInseparableGroup: 'Grupo inseparable de propiedad',
      propertyRecommendedSpareParts: 'Propiedad repuestos recomendados',
      remove: 'Eliminar',
      reset: 'Reiniciar',
      sendOrder: 'Enviar orden',
      spareParts: 'Piezas de repuesto',
    },
  },
}
