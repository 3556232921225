import React, { useState, FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: '#ededed'
  }
}))

interface IBaseDropzone {
  disabled?: boolean
  uploadFiles: (arg0: any) => void
}

const BaseDropzone: FunctionComponent<IBaseDropzone> = ({ disabled, uploadFiles, children }) => {
  const [Highlight, setHighlight] = useState(false)
  const _classes = useStyles()

  const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();

    if (disabled) return;

    setHighlight(true);
  }

  const onDragLeave = () => {
    setHighlight(false);
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const _files = event.dataTransfer.files;

    const _filesArray = [];
    for (var i = 0; i < _files.length; i++) {
      _filesArray.push(_files.item(i));
    }

    uploadFiles(_filesArray);
    setHighlight(false);
  }

  return (
    <React.Fragment>
      <div
        className={`${Highlight ? _classes.highlight : ''}`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {children}
      </div>
    </React.Fragment>
  );
}

export default BaseDropzone
