import React, { Dispatch, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDropzone from './base_dropzone'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },
  dropzone: {
    height: '200px',
    width: '100%',
    border: '2px dashed rgb(187, 186, 186)',
    borderRadius: '0%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '1rem 0',
    cursor: 'pointer',
  },
  logoContainer: {
    height: '200px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      background: '#f7f7f7',
      filter: 'grayscale(1)'
    }
  }
}))

interface IImgDropzone {
  setFile: Dispatch<SetStateAction<File>>
  img: string
}

const ImgDropzone = ({ setFile, img }: IImgDropzone) => {
  const InputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const _classes = useStyles()

  const upload = (file: File) => setFile(file)

  const onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const _files = evt.target.files
    if (_files && _files.length) {
      upload(_files[0])
    }
  }

  let _content = img !== '' ? (
    <div
      className={_classes.logoContainer}
      onClick={() => {
        if (InputRef && InputRef.current) {
          InputRef.current.click()
        }
      }}
    >
      <img
        src={img}
        alt="logo"
        style={{ maxHeight: "60px" }}
      />
    </div>
  ) : (
    <div
      className={_classes.dropzone}
      onClick={() => {
        if (InputRef && InputRef.current) {
          InputRef.current.click()
        }
      }}
    >
      {t<string>('dragFile')}
    </div>
  )

  return (
    <React.Fragment>
      <BaseDropzone uploadFiles={upload}>{_content}</BaseDropzone>
      <input
        ref={InputRef}
        className={_classes.fileInput}
        type='file'
        onChange={onFilesAdded}
        accept="image/*"
      />
    </React.Fragment>
  )
}

export default ImgDropzone
