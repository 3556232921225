import React, { useEffect, useState } from 'react'

export interface ITab {
  page: string
  index: number
}

interface ITabsContext {
  tabs: ITab[]
  setTabs: (tabs: ITab[]) => void
  getCurrentTabs: (location: string) => ITab
  updateCurrentTabs: (location: string, index: number, page?: string) => void
}

const defaultTabs = [
  {
    page: '',
    index: 0,
  },
]
const TabsContext = React.createContext<ITabsContext>({
  tabs: defaultTabs,
  setTabs: () => {},
  getCurrentTabs: () => defaultTabs[0],
  updateCurrentTabs: () => {},
})

type Props = {
  children: React.ReactNode
}

const TabsContextProvider = ({ children }: Props) => {
  const [tabs, setTabs] = useState(defaultTabs)

  const getPageFromLocation = (location: string) => {
    const parts = location.split('/')
    let page = ''
    parts.forEach((part, index) => {
      if ((part.length > 1 && index < part.length) || index === 1) {
        page += part
      }
    })
    return page
  }
  const getCurrentTabs = (location: string) => {
    const currentPage = getPageFromLocation(location)
    return (
      tabs.filter(tab => {
        return tab.page === currentPage
      })[0] ?? defaultTabs[0]
    )
  }

  const updateCurrentTabs = (
    location: string,
    index: number,
    page?: string
  ) => {
    const upd: ITab[] = []
    const currentPage =
      page !== undefined ? page : getPageFromLocation(location)
    tabs.forEach(tab => {
      if (tab.page === currentPage) {
        upd.push({
          page: tab.page,
          index: index,
        })
      } else {
        upd.push(tab)
      }
    })

    if (
      tabs.filter(tab => {
        return tab.page === currentPage
      })
    ) {
      upd.push({
        page: currentPage,
        index: index,
      })
    }

    setTabs(upd)
  }

  return (
    <TabsContext.Provider
      value={{ tabs, setTabs, getCurrentTabs, updateCurrentTabs }}
    >
      {children}
    </TabsContext.Provider>
  )
}

const useTabs = () => React.useContext(TabsContext)

export { TabsContext, TabsContextProvider, useTabs }
