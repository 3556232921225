import React, { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { useTabs } from '../../context'

interface TabPanelProps {
  index: any
  value: any
  className: string
}

const TabPanel: FunctionComponent<TabPanelProps> = props => {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} style={{ height: '100%' }}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  panel: {
    width: '87%'
  }
}))

interface ITabPanels {
  tabs: {
    label?: string
    icon: any
    tooltip?: string
  }[]
  panels: {
    content: any
  }[]
  options: any
}

const Tabpanels: FunctionComponent<ITabPanels> = ({ tabs, panels, options }) => {
  const classes = useStyles()
  const { getCurrentTabs, updateCurrentTabs } = useTabs()
  const location = window.location

  const handleChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    updateCurrentTabs(location.pathname, newIndex)
  }

  const currentIndex = getCurrentTabs(location.pathname).index

  return (
    <div className={classes.root}>
      <Tabs
        value={currentIndex}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='on'
        indicatorColor='primary'
        textColor='primary'
        aria-label='scrollable force tabs example'
        orientation='vertical'
        className={classes.tabs}
      >
        {tabs.map((t, i) =>
          t.tooltip ? (
            <Tooltip key={i} title={t.tooltip ?? ''} placement='bottom' arrow>
              <Tab key={i} icon={t.icon} {...a11yProps(i)} />
            </Tooltip>
          ) : (
            <Tab key={i} icon={t.icon} {...a11yProps(i)} />
          )
        )}
      </Tabs>
      {panels.map((p, i) => (
        <TabPanel value={currentIndex} index={i} key={i} className={classes.panel}>
          {p.content(options)}
        </TabPanel>
      ))}
    </div>
  )
}

export default Tabpanels
