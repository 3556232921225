import React, { Suspense, useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { IUserInfo, getUserInfo, theme, getSppClient } from '@NKE/utils'
import {
  LogoContextProvider,
  TabsContextProvider,
  UserInfoContext,
} from './context'
import { Layout, AppLoader } from './components'
import './i18next'

export default function Root(props) {
  const [UserInfo, setUserInfo] = useState<IUserInfo>()

  useEffect(() => {
    getUserInfo().then(res => {
      if (res) {
        if (res.type !== '1' && res.type !== '2')
          history.pushState(null, '', '/spp')
        setUserInfo(res)
      } else {
        setUserInfo({
          email: '',
          region: '',
          companyName: '',
          type: '',
          loginId: '',
          customerId: '',
          businessPartnerId: '',
          businessPartner: '',
          businessPartnerCode: '',
          crmCustomerId: '',
          handleRevision: '',
          expirationDate: '',
        })
      }
    })
  }, [])

  const _contextValue = {
    userInfo: UserInfo,
  }

  const LazySettings = React.lazy(() => import('./settings'))

  return UserInfo ? (
    <ApolloProvider client={getSppClient()}>
      <TabsContextProvider>
        <LogoContextProvider>
          <UserInfoContext.Provider value={_contextValue}>
            <Layout theme={theme}>
              <Suspense fallback={<AppLoader />}>
                <LazySettings />
              </Suspense>
            </Layout>
          </UserInfoContext.Provider>
        </LogoContextProvider>
      </TabsContextProvider>
    </ApolloProvider>
  ) : (
    <div />
  )
}
